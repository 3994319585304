<template>
  <div>
    <b-container>
      <b-row><h3 class="page-title">MEDIA CHANNELS</h3></b-row>
    </b-container>
    <b-container v-for="(media, index) in materials" :key="index">
      <b-row
        ><b-col cols="4">
          <img :src="media.image" alt="img" class="media-img img-fluid" />
        </b-col>
        <b-col cols="8">
          <h3 class="media-title">{{ media.title }}</h3>
          <p class="media-text">{{ media.text }}</p>
          <a :href="media.button_url" class="media-link" target="_blank">{{
            media.button_label
          }}</a>
        </b-col></b-row
      >
    </b-container>
    <infinite-loading
      @infinite="mediaInfiniteHandler"
      :identifier="mediaInfiniteHandlerId"
    >
      <div slot="no-more" />
      <div slot="spinner" />
      <div slot="no-results" />
    </infinite-loading>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import { mapActions } from 'vuex';

export default {
  name: 'MediaChannels',
  data() {
    return {
      materials: [],
      mediaInfiniteHandlerId: uuidv4(),
      mediaPage: 1,
      mediaNextPage: true,
    };
  },
  methods: {
    ...mapActions({
      getData: 'mediaChannels/getMediaChannels',
    }),
    async getMedia() {
      const { results, next } = await this.getData(this.mediaPage);
      this.mediaNextPage = !!next;
      this.mediaPage += 1;
      this.materials = [...this.materials, ...results];
    },
    async mediaInfiniteHandler($state) {
      try {
        if (this.mediaNextPage) {
          await this.getMedia();
          $state.loaded();
        } else {
          $state.complete();
        }
      } catch (err) {
        console.log('err media infinite', err);
        $state.complete();
      }
    },
  },
};
</script>

<style scoped src='./MediaChannels.css'></style>
